@if (items.length) {
  <form [formGroup]="form">
    <ds-img path="./assets/images/languages.svg" />

    <ds-select
      [field]="{ uniqueFormKey: 'lang', clearable: false }"
      [items]="items"
      [placeholder]="'Select language'"
      (itemSelect)="changeLanguage($event)"
    >
    </ds-select>
  </form>
}
