import { inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { languages } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private readonly translate = inject(TranslateService);

  /**
   * Get current language
   * @return current user language
   */
  getLanguage(): string {
    return this.getCurrentTranslateLanguage();
  }

  /**
   * Used in getLanguage()
   */
  getCurrentTranslateLanguage() {
    return this.translate.getDefaultLang();
  }

  /**
   * Set language
   * @param lang
   */
  setLanguage(lang: any = languages.pl) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
  }
}
